import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { BadgeSectionTypes, CurrentUser, CustomerEventCounterData, MenuItemType } from 'types';
import { MENU_ADMIN_ITEMS, MENU_GUEST_ITEMS, MENU_USER_ITEMS } from 'consts';
import { getEventCounters } from 'api';
import { checkIfSignUpIncomplete, checkIsAdmin, goTo, prepareRedirectPath } from 'utils/services';
import { removeLogInDataFromBrowser } from 'utils/localStoreTools';
import { isEmpty } from 'utils/toggleParams';
import { NextIconWithNotification } from 'components/NextImage/NextIconWithNotification';
import { NextLink } from 'components/NextLink';
import { NextIcon } from 'components/NextImage';
import { Button } from 'components/Button';
import { Burger } from 'components/Burger';
import { AccountAvatar } from 'components/AccountAvatar';
import { MainMenu } from 'components/MainMenu';
import { SideMenuItem } from 'components/SideMenu';
import { getManuItemMap, mainHeaderData } from './mainHeaderData';

import styles from './MainHeader.module.scss';
import NotificationsModal from './NotificationsModal';

export type MainHeaderProps = {
  badge?: BadgeSectionTypes;
  changeOpened?: () => void;
  rightItems?: any;
  searchItems?: any;
  user?: CurrentUser | null;
};

export const MainHeader = ({ badge = {} as BadgeSectionTypes, changeOpened, searchItems, user }: MainHeaderProps) => {
  const router = useRouter();
  const [mainMenuOpened, setMainMenuOpened] = useState(false);
  const [eventCounterData, setEventCounterData] = useState<CustomerEventCounterData>({} as CustomerEventCounterData);
  const isAuthorized = !isEmpty(user);

  const isLoggedIn = !!user?.email;
  const isSignUpIncomplete = checkIfSignUpIncomplete(user);

  const isAdmin = checkIsAdmin(user);
  const {
    unread_messages_count: unreadMessages,
    unread_sell_orders: newSellOrders,
    unread_purchase_orders: newPurchaseOrders,
    unread_offers: newOffers,
    unread_notifications_count: unreadCount
  } = eventCounterData || {};

  useEffect(() => {
    trackEventCounter().then();
  }, []);

  const trackEventCounter = async () => {
    const eventCounterResponse = await getEventCounters();
    if (eventCounterResponse.ok) {
      setEventCounterData(eventCounterResponse);
    }
  };

  const trackEventCounterChangesByVisibility = () => {
    if (document.visibilityState === 'visible') {
      trackEventCounter().then();
    }
  };

  useEffect(() => {
    if (isAdmin) return;
    document.addEventListener('visibilitychange', trackEventCounterChangesByVisibility);
    return () => {
      if (isAdmin) return;
      document.removeEventListener('visibilitychange', trackEventCounterChangesByVisibility);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleMainMenu = () => {
    setMainMenuOpened((opened) => !opened);
  };

  const logOut = () => {
    removeLogInDataFromBrowser();
    router.reload();
  };

  const closeMenu = () => {
    setMainMenuOpened(false);
  };

  const protectedRedirect = (router: any, path: string) => {
    closeMenu();
    if (!isLoggedIn) {
      goTo(router, `/sign-in?redirect=${path}`);
      return;
    }
    if (isSignUpIncomplete) {
      goTo(router, `/sign-up?redirect=${path}`);
      return;
    }
    goTo(router, path);
  };

  const addAdminPanelLinks = (): MenuItemType[] => {
    const links: MenuItemType[] = [];
    if ((!!user?.permissions && user.permissions.includes('dashboard_view')) || user?.role === 'super_admin') {
      links.push('dashboard');
    }
    return links;
  };

  const menuItemMap: Record<MenuItemType, SideMenuItem> = getManuItemMap({
    router,
    protectedRedirect,
    logOut,
    unreadMessages,
    newOffers,
    user,
    newPurchaseOrders,
    newSellOrders,
    closeMenu,
  });

  const getMenuItems = () => {
    if (!isLoggedIn) {
      return MENU_GUEST_ITEMS.map((key: MenuItemType) => menuItemMap[key]);
    }
    if (isAdmin) {
      return [...addAdminPanelLinks(), ...MENU_ADMIN_ITEMS].map((key: MenuItemType) => menuItemMap[key]);
    }
    return MENU_USER_ITEMS.map((key: MenuItemType) => menuItemMap[key]);
  };

  const renderBadge = () => (
    <div className={styles.badge} style={{ backgroundColor: badge.bg_color }}>
      {badge.title}
    </div>
  );

  return (
    <>
      <div className={styles.headerBorder} />
      <div className={styles.container}>
        {badge.is_enabled && renderBadge()}
        <div className={styles.header}>
          <div className={styles.leftSide}>
            <NextIcon
              customClass={styles.menuIcon}
              onClick={toggleMainMenu}
              src="/icons/office/ListDark.svg"
              size={32}
              alt="close"
            />
            <MainMenu active={mainMenuOpened} items={getMenuItems()} setActive={setMainMenuOpened} user={user} />
            <Burger changeOpened={changeOpened} className={styles.burgerIcon} />
            <NextLink href="/" className={styles.logoLink} useDirectLink={router.route === '/'}>
              <NextIcon src="/icons/logo.svg" width={156} alt="Logo" customClass={styles.logo} />
            </NextLink>
            <div className={styles.searchItems}>{searchItems}</div>
          </div>

          <div className={styles.rightSide}>
            <div className={styles.searchMinimized}>{searchItems}</div>
            {!isAuthorized && (
              <div className={styles.signButtons}>
                <Button
                  id="header-sign-in"
                  buttonType={'white'}
                  caption="Sign In"
                  action={() => router.push(`/sign-in${prepareRedirectPath()}`)}
                />
                <Button
                  id="header-sign-up"
                  caption="Sign Up"
                  action={() => router.push(`/sign-up${prepareRedirectPath()}`)}
                />
              </div>
            )}
            {isAuthorized && (
              <div className={styles.userLinks}>
                {mainHeaderData.map((element, index) => {
                  const { src, value, href, notification } = element;
                  return (
                    <NextLink key={index} href={href}>
                      <div className={styles.item}>
                        <NextIconWithNotification
                          src={src}
                          alt={value}
                          notification={notification ? eventCounterData[notification] : undefined}
                        />
                        <span>{value}</span>
                      </div>
                    </NextLink>
                  );
                })}
                <NextLink href={isSignUpIncomplete ? '/sign-up?redirect=/item-management' : '/item-management'}>
                  <div className={styles.item}>
                    <NextIconWithNotification src="/icons/design/PlusCircleBlue.svg" alt="Add Gear" />
                    <span className={styles.gearCreateLink}>Create Gear Profile</span>
                  </div>
                </NextLink>
              </div>
            )}
            {isAuthorized && (
              <>
                <NotificationsModal notification={unreadCount}/>
                <div className={styles.accountIcon}>
                  <NextLink href={`/users/${user?.username}`}>
                    <AccountAvatar
                      sex={user?.sex || ''}
                      photo={user?.photo}
                      isAdmin={isAdmin}
                      customClass={styles.accountIcon}
                    />
                  </NextLink>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
