import { CurrentUser, SupportedEventNotificationKey } from 'types';
import { goTo } from 'utils/services';

export type HeaderDataTypes = {
  src: string;
  value: string;
  href: string;
  notification?: SupportedEventNotificationKey;
};

export const mainHeaderData: HeaderDataTypes[] = [
  {
    src: '/icons/maps/SquaresFour.svg',
    value: 'Feed',
    href: '/feed',
  },
  {
    src: '/icons/commerce/ShoppingBagDark.svg',
    value: 'Sales',
    href: '/my-orders/sell',
    notification: 'unread_sell_orders',
  },
  {
    src: '/icons/commerce/ShoppingCartDark.svg',
    value: 'Purchases',
    href: '/my-orders/purchase',
    notification: 'unread_purchase_orders',
  },
  {
    src: '/icons/communications/ChatCircleDark.svg',
    value: 'Messages',
    href: '/messages',
    notification: 'unread_messages_count',
  },
];

export type MenuItemTypes = {
  router: any;
  protectedRedirect: Function;
  logOut: () => void;
  unreadMessages: number;
  newOffers: number;
  newPurchaseOrders: number;
  user?: CurrentUser | null;
  newSellOrders: number;
  closeMenu?: () => void;
};

export const getManuItemMap = ({
  router,
  protectedRedirect,
  logOut,
  unreadMessages,
  newOffers,
  user,
  newPurchaseOrders,
  newSellOrders,
  closeMenu,
}: MenuItemTypes) => ({
  affiliate: {
    alt: 'icon',
    iconSrc: '/icons/people/HandshakeDark.svg',
    onClick: () => goTo(null, 'https://www.sosendy.com/affiliate', true, closeMenu),
    title: 'Affiliate Program',
  },
  ambassadors: {
    alt: 'icon',
    iconSrc: '/icons/people/UsersDark.svg',
    onClick: () => goTo(null, '/ambassadors', false, closeMenu),
    title: 'SENDY Ambassadors',
  },
  balance: {
    alt: 'icon',
    iconSrc: '/icons/commerce/CurrencyCircleDollarDark.svg',
    onClick: () => protectedRedirect(router, '/transactions'),
    title: 'Balance',
  },
  blog: {
    alt: 'icon',
    iconSrc: '/icons/media/NewspaperDark.svg',
    onClick: () => goTo(router, '/blog', false, closeMenu),
    title: 'SENDYpedia',
  },
  contact: {
    alt: 'icon',
    iconSrc: '/icons/communications/EnvelopeSimpleDark.svg',
    onClick: () => goTo(null, '/c/contact', true, closeMenu),
    title: 'Contact',
  },
  create: {
    alt: 'icon',
    iconSrc: '/icons/design/PlusCircleDark.svg',
    onClick: () => protectedRedirect(router, '/item-management'),
    title: 'Create Gear',
    hideOnDesktop: true,
  },
  dashboard: {
    alt: 'icon',
    iconSrc: '/icons/design/SquaresFourDark.svg',
    onClick: () => goTo(router, '/admin-panel/dashboard', false, closeMenu),
    title: 'Dashboard',
  },
  divider: {
    alt: 'divider',
    title: '',
  },
  faq: {
    alt: 'icon',
    iconSrc: '/icons/security/QuestionDark.svg',
    onClick: () => goTo(router, '/faq', false, closeMenu),
    title: 'FAQ',
  },
  favorites: {
    alt: 'icon',
    iconSrc: '/icons/weather/StarDark.svg',
    onClick: () => protectedRedirect(router, '/my-favorites'),
    title: 'Favorites',
  },
  feed: {
    alt: 'icon',
    iconSrc: '/icons/maps/SquaresFour.svg',
    onClick: () => protectedRedirect(router, '/feed'),
    title: 'Feed',
    hideOnDesktop: true,
  },
  gear: {
    alt: 'icon',
    iconSrc: '/icons/office/ListDashesDark.svg',
    onClick: () => protectedRedirect(router, '/my-gear'),
    title: 'Gear',
  },
  guide: {
    alt: 'icon',
    iconSrc: '/icons/office/NotebookDark.svg',
    onClick: () => goTo(router, '/user-guide', false, closeMenu),
    title: 'User guide',
  },
  support: {
    alt: 'icon',
    iconSrc: '/icons/system/Support.svg',
    onClick: () => protectedRedirect(router, '/messages?active=support'),
    title: 'Support',
  },
  logout: {
    alt: 'icon',
    iconSrc: '/icons/system/SignOutDark.svg',
    onClick: logOut,
    title: 'Log out',
  },
  media: {
    alt: 'icon',
    iconSrc: '/icons/games/MedalDark.svg',
    onClick: () => goTo(null, 'https://www.sosendy.com/media', true, closeMenu),
    title: 'Media/PR',
  },
  messages: {
    alt: 'icon',
    iconSrc: '/icons/communications/ChatCircleDark.svg',
    onClick: () => protectedRedirect(router, '/messages'),
    title: 'Messages',
    hideOnDesktop: true,
    notification: unreadMessages,
  },
  offers: {
    alt: 'icon',
    iconSrc: '/icons/commerce/TagDark.svg',
    onClick: () => protectedRedirect(router, '/my-offers'),
    title: 'Offers',
    notification: newOffers,
  },
  profile: {
    alt: 'icon',
    iconSrc: '/icons/UserCircle.svg',
    onClick: () => protectedRedirect(null, `/users/${user?.username}`),
    title: 'My Profile',
  },
  purchases: {
    alt: 'icon',
    iconSrc: '/icons/commerce/ShoppingCartDark.svg',
    onClick: () => protectedRedirect(router, '/my-orders/purchase'),
    title: 'Purchases',
    hideOnDesktop: true,
    notification: newPurchaseOrders,
  },
  retail: {
    alt: 'icon',
    iconSrc: '/icons/commerce/StorefrontDark.svg',
    onClick: () => goTo(null, '/retail-partner', false, closeMenu),
    title: 'Retail Partners',
  },
  sales: {
    alt: 'icon',
    iconSrc: '/icons/commerce/ShoppingBagDark.svg',
    onClick: () => protectedRedirect(router, '/my-orders/sell'),
    title: 'Sales',
    hideOnDesktop: true,
    notification: newSellOrders,
  },
  settings: {
    alt: 'icon',
    iconSrc: '/icons/system/GearSixDark.svg',
    onClick: () => goTo(router, '/account-settings', false, closeMenu),
    title: 'Settings',
  },
  snowboard: {
    alt: 'icon',
    iconSrc: '/icons/SnowboardNew.svg',
    onClick: () => goTo(null, 'https://www.sosendy.com/faq-snowboard', true, closeMenu),
    title: 'Q&A Snowboard',
  },
  skis: {
    alt: 'icon',
    iconSrc: '/icons/SkiNew.svg',
    onClick: () => goTo(null, 'https://www.sosendy.com/faq-skis', true, closeMenu),
    title: 'Q&A Skis',
  },
  story: {
    alt: 'icon',
    iconSrc: '/icons/office/BookOpenDark.svg',
    onClick: () => goTo(null, '/c/about', true, closeMenu),
    title: 'Story',
  },
  titleUseful: {
    alt: '',
    title: 'Useful Information',
  },
  titlePartners: {
    alt: '',
    title: 'For Partners',
  },
  titleAbout: {
    alt: '',
    title: 'Other',
  },
  titleTutorials: {
    alt: '',
    title: 'Tutorials',
  },
  buyTutorial: {
    alt: 'icon',
    iconSrc: '/icons/office/Buy.svg',
    onClick: () => goTo(router, '/buy', false, closeMenu),
    title: 'Buy',
  },
  sellTutorial: {
    alt: 'icon',
    iconSrc: '/icons/office/Sell.svg',
    onClick: () => goTo(router, '/sell', false, closeMenu),
    title: 'Sell',
  },
  rentTutorial: {
    alt: 'icon',
    iconSrc: '/icons/office/Rent.svg',
    onClick: () => goTo(router, '/rent', false, closeMenu),
    title: 'Rent',
  },
});
